export const flightModes = { ARRIVAL: "arrival", DEPARTURE: "departure", BOTH: "both" };

export const flightStatus = { APPROVED: "APPROVED", PENDING: "PENDING", DECLINED: "DECLINED" };

export const flightTypes = {
  SCHEDULED: "SCHEDULED",
  DELAYED: "DELAYED",
  ENROUTE: "AIRBORNE",
  LANDED: "LANDED",
  CANCELED: "CANCELLED",
  RETURNED: "RETURNED",
};

export const operationModes = { DEPARTURE: "Departure", ARRIVAL: "Arrival", TURNAROUND: "Turn Around" };
