import React from "react";
import { IoAirplaneOutline } from "react-icons/io5";
import { flightTypes } from "../constants/enums";
import PropTypes from "prop-types";
import { MdOutlineHexagon } from "react-icons/md";

const FlightCard = ({ data }) => {
  const { SCHEDULED, DELAYED, ENROUTE, LANDED, CANCELED, RETURNED } = flightTypes;

  const statusStyles = (status) => {
    if (status === SCHEDULED) return "bg-[#0E63F4]";
    if (status === DELAYED) return "bg-[#F10E0E]";
    if (status === ENROUTE) return "bg-[#C70B69]";
    if (status === LANDED) return "bg-[#0BD488]";
    if (status === CANCELED) return "bg-[#F10E0E]";
    if (status === RETURNED) return "bg-gray-700";
    return "bg-gray-700";
  };

  const isEnRoute = data?.flightType === ENROUTE;
  const isLanded = data?.flightType === LANDED;

  return (
    <div
      data-cy={`flight-${data?.flightNumber}`}
      className="bg-white dark:bg-black p-5 pt-12 rounded-lg shadow border border-[#e7e7e7] relative"
    >
      <p
        className={`absolute left-0 top-0 py-2 px-4 flex items-center gap-2 space-x-2 rounded-lg text-white dark:text-white ${statusStyles(
          data?.flightType
        )}`}
      >
        <MdOutlineHexagon /> {data?.flightType}
      </p>
      <div className="flex flex-col sm:flex-row items-center gap-1">
        <div className="max-w-[35%] w-full pr-4 flex items-center justify-center">
          <div className="flex flex-col items-center gap-1">
            {data?.carrier?.avatar && (
              <figure className="bg-white p-2 flex flex-col items-center">
                <img src={data?.carrier?.avatar} alt={data?.carrier?.name} className="w-fit max-w-[100px]" />
              </figure>
            )}

            <h3 className="text-[#092C4C] whitespace-nowrap text-left text-sm 2xl:text-base font-[500] capitalize">
              {data?.carrier?.name}
            </h3>
            <h3 className="text-[#092C4C] whitespace-nowrap text-left text-sm 2xl:text-base font-[500] capitalize">
              {data?.flightNumber}
            </h3>
          </div>
        </div>

        <div className="w-full sm:border-l h-full sm:px-4 uppercase">
          <div className="flex justify-between w-full">
            <div>
              <h3 data-cy="departure-airport" className="text-[#092C4C] font-[500] text-lg 2xl:text-xl">
                {data?.departure?.airport?.iata}
              </h3>
              <p className="text-[#4F4F4F]">{data?.departure?.country?.country}</p>
            </div>
            <div>
              <h3 data-cy="arrival-airport" className="text-[#092C4C] font-[500] text-lg text-right 2xl:text-xl">
                {data?.arrival?.airport?.iata}
              </h3>
              <p className="text-[#4F4F4F] text-right">{data?.arrival?.country?.country}</p>
            </div>
          </div>

          <div className="flex justify-between items-center gap-4 w-full -my-1">
            <hr className="border border-[#092C4C69] dark:border-primary w-full" />
            <IoAirplaneOutline size={50} className="text-primary" />
            <hr className="border border-[#092C4C69] dark:border-primary w-full" />
          </div>

          <div className="flex justify-between w-full">
            <div>
              <p className="text-[#092C4C] font-[500] text-lg 2xl:text-xl">
                {(isEnRoute || isLanded) && data?.actualTimeOfDeparture
                  ? data?.actualTimeOfDeparture
                  : data?.departure?.time?.local}
              </p>
              <p className="text-[#4F4F4F]">{(isEnRoute || isLanded) && data?.actualTimeOfDeparture ? "ATD" : "ETD"}</p>
            </div>
            <div>
              <p className="text-[#092C4C] font-[500] text-lg text-right 2xl:text-xl">
                {isLanded && data?.actualTimeOfArrival ? data?.actualTimeOfArrival : data?.arrival?.time?.local}
              </p>
              <p className="text-[#4F4F4F] text-right">{isLanded && data?.actualTimeOfArrival ? "ATA" : "ETA"} </p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t mt-5 pt-5 text-[#292929]">
        <p className="font-normal text-center capitalize">
          {data?.comments?.slice(0, 80) || "No Additional Information"}
        </p>
      </div>
    </div>
  );
};

export default FlightCard;

FlightCard.propTypes = {
  data: PropTypes.object,
};
