import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "./context/ThemeContext";
import { Provider, ErrorBoundary } from "@rollbar/react";
import { isProduction } from "./utils/utility";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

const rollbarConfig = {
  accessToken: process.env.REACT_APP_FLIGHT_ROLLBAR_TOKEN,
  environment: process.env.NODE_ENV,
  captureUncaught: isProduction(),
  captureUnhandledRejections: isProduction(),
  payload: {
    source_map_enabled: isProduction(),
    // code_version:
    guess_uncaught_frames: isProduction(),
  },
  ignoredMessages: ["(unknown): Script error.", "Script error.", "Script error"],
  enabled: isProduction(),
};

root.render(
  <React.StrictMode>
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <App />
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>
  </React.StrictMode>
);
