import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../utils/Axios";
import FlightLayout from "./FlightLayout";
import { flightModes, flightStatus } from "../constants/enums";
import EmptyListItem from "./utils/EmptyListItem";
import { MdOutlineFlight } from "react-icons/md";
import { ScaleLoader } from "react-spinners";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BiErrorCircle } from "react-icons/bi";
import io from "socket.io-client";
import { environment } from "../config/environment";

const {
  api: { uri },
} = environment;

const socket_url = uri.slice(0, -4);

const socket = io(socket_url);

const Flights = () => {
  const [page] = useState(1);
  const [scrollDirection, setScrollDirection] = useState("up");
  const [arrivals, setArrivals] = useState([]);
  const [departures, setDepartures] = useState([]);

  const queryClient = useQueryClient();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code")?.toUpperCase();

  const fetchSchedules = async () => {
    try {
      const res = await API.get(
        `/schedules/daily-display?page=${page}&limit=100&code=${code}&status=${flightStatus.APPROVED}&mode=${flightModes.BOTH}`
      );

      return res;
    } catch (e) {}
  };

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["flights"],
    queryFn: fetchSchedules,
  });

  const docs = data?.data?.data?.docs;

  const sortRecentFlights = () => {
    const currentTime = new Date(); // Get the current date and time

    // Function to calculate the time difference and determine if the flight is in the past
    const calculateTimeDiff = (flightTime) => {
      const timeDiff = flightTime - currentTime;
      const isPast = timeDiff < 0;
      return { timeDiff: Math.abs(timeDiff), isPast };
    };

    // Function to parse flight date and time into a Date object
    const parseFlightDateTime = (date, time) => {
      // Pad the hour and minute with leading zeros if necessary
      const [hour, minute] = time.split(":").map((num) => num.padStart(2, "0"));
      const dateTimeString = `${date}T${hour}:${minute}:00`;
      const flightTime = new Date(dateTimeString);
      if (isNaN(flightTime)) {
        console.error("Invalid Date:", dateTimeString);
      }
      return flightTime;
    };

    // Map the flights to include the time difference and past status
    const mapFlightsWithTimeDiff = (flights) => {
      return flights.map((flight) => {
        const flightTime = parseFlightDateTime(flight.departure.date.local, flight.departure.time.local);
        const { timeDiff, isPast } = calculateTimeDiff(flightTime);
        return { ...flight, timeDiff, isPast };
      });
    };

    // Sort the flights with past flights at the bottom
    const sortFlights = (flights) => {
      return flights.sort((a, b) => {
        if (a.isPast === b.isPast) {
          return a.timeDiff - b.timeDiff;
        }
        return a.isPast - b.isPast; // Past flights get sorted to the bottom
      });
    };

    // Process arrival and departure flights
    const arrivalFlightsWithTimeDiff = mapFlightsWithTimeDiff(docs?.arrival || []);
    const departureFlightsWithTimeDiff = mapFlightsWithTimeDiff(docs?.departure || []);

    const sortedArrivalFlights = sortFlights(arrivalFlightsWithTimeDiff);
    const sortedDepartureFlights = sortFlights(departureFlightsWithTimeDiff);

    setDepartures(sortedDepartureFlights);
    setArrivals(sortedArrivalFlights);
  };

  useEffect(() => {
    sortRecentFlights();
    const interval =
      docs?.arrival?.length > 0 || docs?.departure?.length > 0 ? setInterval(sortRecentFlights, 300000) : null;
    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [docs]); //eslint-disable-line

  useEffect(() => {
    // Add the socket event listener when the component mounts
    const handleUpdateSchedule = (updatedData) => {
      // Update the data in the React Query cache if the updated event concerns the current station
      if (updatedData?.code?.includes(code)) {
        queryClient.invalidateQueries();
      }
    };

    socket.on("updateSchedule", handleUpdateSchedule);
    socket.on("create-schedule", handleUpdateSchedule);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("updateSchedule", handleUpdateSchedule);
    };
  }, [code, queryClient]);

  useEffect(() => {
    // Function to handle scrolling up and down
    const handleAutoScroll = () => {
      const scrollStep = 1; // Number of pixels to scroll per step

      // Get the current scroll position
      let currentPosition = window.scrollY;

      // Calculate the new scroll position based on the direction
      const newPosition = scrollDirection === "up" ? currentPosition - scrollStep : currentPosition + scrollStep;

      // Scroll the page to the new position
      window.scrollTo(0, newPosition);

      // Check if we need to change the scroll direction
      if (newPosition <= 0) {
        setScrollDirection("down");
      } else if (newPosition >= document.body.clientHeight - window.innerHeight) {
        setScrollDirection("up");
      }
    };

    // Start auto-scrolling interval if there's data
    const scrollInterval =
      docs?.arrival?.length > 0 || docs?.departure?.length > 0 ? setInterval(handleAutoScroll, 30) : null;

    // Clean up the interval when the component unmounts
    return () => clearInterval(scrollInterval);
  }, [scrollDirection, docs]);

  return isLoading ? (
    <div className="h-[80vh] flex items-center justify-center">
      <ScaleLoader color="#DF7024" loading={isLoading} height={60} />
    </div>
  ) : (docs?.arrival?.length === 0 && docs?.departure?.length === 0) || isError ? (
    <div className="flex items-center justify-center min-h-[350px] md:min-h-[400px] h-[80vh]">
      <EmptyListItem
        icon={
          isError ? (
            <BiErrorCircle className="text-2xl text-primary" />
          ) : (
            <MdOutlineFlight className="text-2xl text-primary" />
          )
        }
        text1={isError ? "Oops, an error occured. please try again" : "No flight data available to view."}
        button={
          <button
            type="button"
            className="btn btn-primary btn-block xs:btn-wide px-8 text-sm md:text-base normal-case"
            onClick={refetch}
          >
            {isError ? "Retry" : "Reload"}
          </button>
        }
      />
    </div>
  ) : (
    <div className="bg-container py-12 grid lg:grid-cols-2 gap-10 lg:gap-20">
      <FlightLayout title="Arrivals" data={arrivals} />

      <FlightLayout title="Departures" data={departures} />
    </div>
  );
};

export default Flights;
